<template>
    <div v-loading="$wait.is('loading')" class="card card-f-h" element-loading-spinner="el-custom-spinner">
        <ModalAddRoleType
            :visible="modalAddVisible"
            @close="modalAddVisible = false"
            @refresh="getAllRolesType"
        />

        <div class="flex justify-between pb-4 border-b">
            <el-select v-model="selectedRoleId">
                <el-option
                    v-for="item in rolesType"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
            </el-select>
            <div>
                <el-button type="danger" :loading="$wait.is('loading.deleting')" @click="deleteRole">
                    {{ $t('settings.delete_role') }}
                </el-button>
                <el-button type="primary" @click="showAddModal">
                    {{ $t('settings.add_role') }}
                </el-button>
            </div>
        </div>
        <RolesAndPermissionsTable v-show="rolesType.length" :permissionsData="permissionsData" :role="selectedRole" @update="update" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
export default {
    components: {
        ModalAddRoleType:         () => import(/* webpackChunkName: "RolesAndPermissionsView/ModalAddRoleType" */ './modals/ModalAddRoleType.vue'),
        RolesAndPermissionsTable: () => import(/* webpackChunkName: "RolesAndPermissionsView/RoleAndPermissionsTable" */ './components/RolesAndPermissionsTable'),
    },

    data() {
        return {
            noDataInformation: false,
            selectedRoleId:    null,
            rolesType:         [],
            permissionsData:   {},
            activeTab:         'manager',
            modalAddVisible:   false,
        };
    },

    computed: {
        selectedRole() {
            return this.rolesType.find(item => item.id === this.selectedRoleId);
        },
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('settings.settings_role_and_permissions'));
        this.$store.commit('setActiveMenuItem', '99-4');

        this.getAllPermissions();
    },

    methods: {
        async getAllPermissions() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/roles_and_permissions/permissions`);
            this.permissionsData = data;
            this.$wait.end('loading');
            this.getAllRolesType();
        },

        async getAllRolesType() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/roles_and_permissions/roles`);
            this.rolesType = data;
            this.noDataInformation = data.length;
            if (!this.selectedRoleId) {
                this.selectedRoleId = this.rolesType[0]?.id;
            }
            this.$wait.end('loading');
        },

        showAddModal() {
            this.modalAddVisible = true;
        },

        async update({ roleId, permissionId, checkbox }) {
            this.$wait.start('loading');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/roles_and_permissions/roles/${roleId}`, {
                permission: permissionId,
                assign:     checkbox,
            });
            this.getAllRolesType();
            this.$notify.success({ title: this.$t('common.success') });
        },

        async deleteRole() {
            this.$wait.start('loading.deleting');
            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/roles_and_permissions/roles/${this.selectedRoleId}`);
            this.$wait.end('loading.deleting');
            this.selectedRoleId = null;
            this.getAllRolesType();
            this.$notify.success({ title: this.$t('common.success') });
        },
    },
};
</script>
